import _ from "lodash";
import dayjs from "dayjs";
import { RECENT_DATA_COUNT } from "./constants";

export * as Storage from "./storage";
export { CARD_TYPES } from "./constants";
export { getCountry } from "./getCountry";
export { imageUrlBuilder } from "./imageUrlBuilder";
export { imageUrlUpdater } from "./imageUrlBuilder";
export { filterTeeTimeData } from "./filterTeeTime";
export { getAverageTeeTime } from "./getAverageTeeTime";
export { areCurrenciesSame } from "./areCurrenciesSame";
export {
  isEmail,
  isPassword,
  isValidatePassword,
  isPhoneNumber,
  isPostalCode,
} from "./is";

export function updateRecentData(array, element) {
  if (!_.some(array, element)) {
    if (array.length === RECENT_DATA_COUNT) {
      array.pop();
    }
    array.splice(0, 0, element);
  }
}

export function getFullName(user) {
  if (!user) return null;
  const { firstName, lastName } = user;
  return lastName ? `${firstName} ${lastName}` : firstName;
}

export function getNextDays(date, count = 5) {
  return Array.from({ length: count }).map((_, i) => dayjs(date).add(i, "day"));
}

export function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export function getDistance(lat1, lon1, lat2, lon2) {
  var R = 6371 * 0.621371; // miles
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var dlat1 = toRad(lat1);
  var dlat2 = toRad(lat2);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(dlat1) * Math.cos(dlat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

export const BOOK_TEETIME = "BOOK_TEETIME";
export const GET_LISTING_BOOKING = "GET_LISTING_BOOKING";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const PAYMENT_UPDATE_BOOKING = "PAYMENT_UPDATE_BOOKING";
export const FACILITES = "FACILITES";
export const ADD_FAV_CLUB = "ADD_FAV_CLUB";
export const GET_FAV_CLUB = "GET_FAV_CLUB";
export const GET_PARTNER_FACILITY = "GET_PARTNER_FACILITY";
export const GET_OTP = "GET_OTP";
export const GET_PAYMENT_DETAIL = "GET_PAYMENT_DETAIL";
export const CANCEL_BOOKING = "CANCEL_BOOKING";

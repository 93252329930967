export function arrangeNavData(data) {
  // Extract items array from the input data
  let items = data;

  // Create a map to easily find items by their id
  let itemMap = new Map();
  items.forEach((item) => {
    // Create a new object to avoid modifying the original one
    let newItem = {
      ...item,
      attributes: { ...item.attributes, child: { data: [] } },
    };
    itemMap.set(item.id, newItem);
  });

  // Initialize an array to hold the top-level items
  let topLevelItems = [];

  // Iterate over items and establish the parent-child relationships
  items.forEach((item) => {
    let newItem = itemMap.get(item.id);
    let parentId = item.attributes.parent?.data
      ? item.attributes.parent.data.id
      : null;

    if (parentId) {
      let parentItem = itemMap.get(parentId);
      if (!parentItem.attributes.child) {
        parentItem.attributes.child = { data: [] };
      }
      parentItem.attributes.child.data.push(newItem);
    } else {
      topLevelItems.push(newItem);
    }
  });

  // Sort the top-level items by order
  topLevelItems.sort((a, b) => a.attributes.order - b.attributes.order);

  // Recursively sort child items
  function sortChildItems(item) {
    if (item.attributes.child && item.attributes.child.data.length) {
      item.attributes.child.data.sort(
        (a, b) => a.attributes.order - b.attributes.order
      );
      item.attributes.child.data.forEach(sortChildItems);
    }
  }

  topLevelItems.forEach(sortChildItems);

  return topLevelItems;
}

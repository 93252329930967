import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

export default function ChangeLanguage(props) {
  const [dropIcon, setDropIcon] = useState(false);

  const ArrowUp = () => {
    return <ChevronUpIcon className="h-6 w-6 text-white" aria-hidden="true" />;
  };
  const ArrowDown = () => {
    return (
      <ChevronDownIcon className="h-6 w-6 text-white" aria-hidden="true" />
    );
  };

  const countries = [
    { code: "GB", language: "en" },
    { code: "DE", language: "de" },
  ];

  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <span
          className={`text-[16px] capitalize font-sans font-medium text-[white] ${props.titleStyle} flex space-x-3`}
        >
          <img
            src={`https://flagcdn.com/w20/${props?.title?.code?.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${props?.title?.code?.toLowerCase()}.png 2x`}
            className="w-[40px] h-[20px] bg-white"
          />
          <p>{props?.title?.value?.toUpperCase()}</p>
        </span>
        {dropIcon === false ? <ArrowDown /> : <ArrowUp />}
      </Popover.Button>
      <Transition
        beforeLeave={() => setDropIcon(false)}
        beforeEnter={() => setDropIcon(true)}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-[70px] xl:left-6 z-10 mt-4 flex  max-w-min -translate-x-1/2 rounded-lg">
          {({ close }) => (
            <div className="w-32 flex flex-col gap-y-2 items-start rounded-lg bg-white text-sm leading-6 text-black p-1  shadow-[0_0px_20px_#3c7fed90]">
              {countries.map((item, index) => (
                <div
                  className={`flex w-full justify-start gap-x-3 items-center pl-2 pr-1 py-1 ${
                    item?.language === props?.title?.language
                      ? " hover:cursor-default"
                      : "hover:bg-slate-200 hover:cursor-pointer"
                  } `}
                  onClick={() => {
                    props.handleLanguageChange(item);
                    close();
                  }}
                  key={index}
                >
                  <img
                    className="w-[40px] h-[20px] min-w-[40px] max-w-[40px]"
                    src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                    alt="flag"
                  />
                  <div
                    key={item.language}
                    className={` ${
                      item?.language === props?.title?.language
                        ? "text-primary"
                        : "text-black"
                    } text-[15px] font-sans font-semibold `}
                  >
                    {item.language.toUpperCase()}
                  </div>
                  {item?.language === props?.title?.language ? (
                    <DoneRoundedIcon
                      fontSize="small"
                      sx={{ marginBottom: "5px", color: "green" }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

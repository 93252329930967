import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";

const MapControls = ({
  handleZoomIn,
  handleZoomOut,
  handleToggleView,
  handleShare,
  centerMapToUserLocation,
}) => {
  const options = [
    {
      title: "Map",
      value: "roadmap",
    },
    {
      title: "Satellite",
      value: "satellite",
    },
  ];

  const shareOptions = [
    {
      title: "Facebook",
      value: "facebook",
      icon: <FacebookIcon />,
    },
    {
      title: "Twitter",
      value: "twitter",
      icon: <XIcon />,
    },
    {
      title: "Whatsapp",
      value: "whatsapp",
      icon: <WhatsAppIcon />,
    },
    {
      title: "Copy",
      value: "copy",
      icon: <ContentCopyIcon />,
    },
  ];
  return (
    <div className="space-y-[10px]">
      <div className={ButttonContainer} onClick={centerMapToUserLocation}>
        <NearMeOutlinedIcon sx={{ height: 20, width: 20 }} />
      </div>
      <div className={ButttonContainer} onClick={handleZoomIn}>
        <AddIcon sx={{ height: 20, width: 20 }} />
      </div>
      <div className={ButttonContainer} onClick={handleZoomOut}>
        <RemoveIcon sx={{ height: 20, width: 20 }} />
      </div>
      <Popover className="relative">
        <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
          <div className={ButttonContainer}>
            <LayersOutlinedIcon />
          </div>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute left-[-17px] z-10  flex  max-w-min -translate-x-1/2 px-4 mt-2">
            <div className="w-30  shrink  bg-white p-3 text-sm font-semibold leading-6 text-gray-900 ring-1 ">
              {options.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center cursor-pointer hover:text-primary space-x-2 block p-2  text-[15px] font-sans font-medium"
                  onClick={() => handleToggleView(item)}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <Popover className="relative">
        <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
          <div className={ButttonContainer}>
            <ShareOutlinedIcon />
          </div>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute left-[-17px] z-10  flex  max-w-min -translate-x-1/2 px-4 mt-2">
            <div className="w-30  shrink  bg-white p-3 text-sm font-semibold leading-6 text-gray-900 ring-1 ">
              {shareOptions.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row gap-2 items-center cursor-pointer hover:text-primary space-x-2 p-2  text-[15px] font-sans font-medium"
                  onClick={() => handleShare(item.value)}
                >
                  {item.icon}
                  {item.title}
                </div>
              ))}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default MapControls;

const ButttonContainer = `
flex justify-center items-center bg-[#ffffff] cursor-pointer border-[#000000] border-solid border-[1px] h-[35px] w-[35px] lg:h-[50px] lg:w-[50px]
`;

import bgImage from "@assets/images/bg.png";
import GolfMagazinImg from "@assets/images/Golf Magazin.png";
import TitleistImg from "@assets/images/Titleist.png";
import play from "@assets/images/play.png";
import golfBoll from "@assets/images/golfBoll.png";
import golfStick from "@assets/images/golfStick.png";
import clubImg from "@assets/images/clubImg.png";
import clubImg2 from "@assets/images/clubImg2.png";
import clubImg3 from "@assets/images/clubImg3.png";
import clubImg4 from "@assets/images/clubImg4.png";
import fingureIndicator from "@assets/images/fingureIndicator.png";

export const navOptions = [
  { name: "TEE TIMES", id: 1, path: "/" },
  { name: "FEED", id: 2, path: "/feeds" },
  { name: "COURSES", id: 3, path: "/courses" },
  { name: "BRANDS", id: 4, path: "/brands" },
  { name: "BLOG", id: 5, path: "/blog" },
];

export const beastDeals = [
  {
    courseName: "Golf Club St. Leon-Rot",
    distance: "55km",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    hotal: "Four seasons hotel",
    edu: "Leon-Rot Golf Schoole",
    reviews: 9382,
    rs: [{ price: "$43" }, { price: "$43" }],
    timing: "9:30PM - 6:30PM",
  },
  {
    courseName: "Golf Resort Bad Griesbach",
    distance: "34km",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 4,
    hotal: "Four seasons hotel",
    edu: "Leon-Rot Golf Schoole",
    reviews: 938,
    rs: [{ price: "$43" }, { price: "$43" }],
    timing: "9:30PM - 6:30PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    distance: "44km",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 3,
    hotal: "Four seasons hotel",
    edu: "Leon-Rot Golf Schoole",
    reviews: 2822,
    rs: [{ price: "$43" }, { price: "$43" }],
    timing: "9:30PM - 6:30PM",
  },
];

export const proGolfInfluncer = [
  {
    name: "Andrew Chandler  ",
    details:
      "I am a professional golf course photog rapher as well as a golf professional. I am a professional golf course photog rapher as well as a golf professional. I am a professional golf course photog rapher as well as a golf professional. Traveling the world golf… ",
    url: bgImage,
    days: "5 days ago",
    rounds: "335",
    followers: "434",
    location: "North Hants Golf Club",
  },

  {
    name: "Kevin Zimmerman",
    details:
      "I am a professional golf course photog rapher as well as a golf professional. I am a professional golf course photog rapher as well as a golf professional.French professional player aspiring to become one of the world's best golfers. Winner of the 2015 Amateur...",
    url: bgImage,
    days: "3 days ago",
    rounds: "522",
    followers: "411",
    location: "North Hants Golf Club",
  },
  {
    name: "Kevin Zimmerman",
    details:
      "I am a professional golf course photog rapher as well as a golf professional. French professional player aspiring to become one of the world's best golfers. Winner of the 2015 Amateur...",
    url: bgImage,
    days: "3 days ago",
    rounds: "522",
    followers: "784",
    location: "North Hants Golf Club",
  },
  {
    name: "Kevin Zimmerman",
    details:
      "I am a professional golf course photog rapher as well as a golf professional. I am a professional golf course photog rapher as well as a golf professional.French professional player aspiring to become one of the world's best golfers. Winner of the 2015 Amateur...",
    url: bgImage,
    days: "3 days ago",
    rounds: "522",
    followers: "411",
    location: "North Hants Golf Club",
  },
  {
    name: "Kevin Zimmerman",
    details:
      "I am a professional golf course photog rapher as well as a golf professional. French professional player aspiring to become one of the world's best golfers. Winner of the 2015 Amateur...",
    url: bgImage,
    days: "3 days ago",
    rounds: "522",
    followers: "784",
    location: "North Hants Golf Club",
  },
];

export const golfNews = [
  {
    news: "Golf  Griesbach",
    details:
      "From the Tour-inspired playability of T100 to the extreme forgiveness of T400, T·Series irons offer a model for every level of player. Get an idea of which model is right for your game with our iron selection…",
    url: bgImage,
    golfImg: GolfMagazinImg,
    days: "3 days ago",
    likes: "5",
    comments: "4",
  },
  {
    news: "Golf  Griesbach",
    details:
      "From the Tour-inspired playability of T100 to the extreme forgiveness of T400, T·Series irons offer a model for every level of player. Get an idea of which model is right for your game with our iron selection…",
    url: bgImage,
    golfImg: TitleistImg,
    days: "3 days ago",
    likes: "5",
    comments: "4",
  },
];

export const golfVactionDestination = [
  {
    news: "Phoenix , AZ ",
    details:
      "From the Tour-inspired playability of T100 to the extreme forgiveness of T400, T·Series irons offer a model for every level of player. Get an idea of which model is right for your game with our iron selectio",
    url: bgImage,
    days: "5 days ago",
    likes: "5",
    comments: "42",
  },
  {
    news: "Las Vegas , NV",
    details:
      "From the Tour-inspired playability of T100 to the extreme forgiveness of T400, T·Series irons offer a model for every level of player. Get an idea of which model is right for your game with our iron selectio",
    url: bgImage,
    days: "3 days ago",
    likes: "5",
    comments: "4",
  },
  {
    news: "Orlando , FL",
    details:
      "From the Tour-inspired playability of T100 to the extreme forgiveness of T400, T·Series irons offer a model for every level of player. Get an idea of which model is right for your game with our iron selectio",
    url: bgImage,
    days: "3 days ago",
    likes: "5",
    comments: "4",
  },
  {
    news: "Orlando , FL",
    details:
      "From the Tour-inspired playability of T100 to the extreme forgiveness of T400, T·Series irons offer a model for every level of player. Get an idea of which model is right for your game with our iron selectio",
    url: bgImage,
    days: "3 days ago",
    likes: "5",
    comments: "4",
  },
  {
    news: "Orlando , FL",
    details:
      "From the Tour-inspired playability of T100 to the extreme forgiveness of T400, T·Series irons offer a model for every level of player. Get an idea of which model is right for your game with our iron selectio",
    url: bgImage,
    days: "3 days ago",
    likes: "5",
    comments: "4",
  },
];

export const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "NewHampshire",
  "NewJersey",
  "NewMexico",
  "NewYork",
  "NorthCarolina",
  "NorthDakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "RhodeIsland",
  "SouthCarolina",
  "SouthDakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "WestVirginia",
  "Wisconsin",
  "Wyoming",
];

export const country = [
  { countryName: "Aruba", city: "Aruba" },
  { countryName: "Ireland", city: "Ireland" },
  { countryName: "Costa Rica", city: "Costa Rica" },
  { countryName: "Jamaica", city: "Jamaica" },
  { countryName: "Bermuda", city: "Bermuda" },
  { countryName: "Mexico", city: "Mexico" },
];

export const morTeeTime = [
  { players: "1-4 Players", rs: "70", rateType: "Online Rate", time: "9:54" },
  { players: "1-4 Players", rs: "50", rateType: "Online Rate", time: "9:54" },
  { players: "1-4 Players", rs: "80", rateType: "Online Rate", time: "9:54" },
  { players: "1-4 Players", rs: "45", rateType: "Online Rate", time: "9:54" },
  { players: "1-4 Players", rs: "35", rateType: "Online Rate", time: "9:54" },
  { players: "1-4 Players", rs: "40", rateType: "Online Rate", time: "9:54" },
];
export const midTime = [
  { players: "1-4 Players", rs: "65", rateType: "Online Rate", time: "9:54" },
  { players: "1-4 Players", rs: "90", rateType: "Online Rate", time: "9:54" },
  { players: "1-4 Players", rs: "40", rateType: "Online Rate", time: "9:54" },
];
export const Policies = [
  { name: "Metal spikes allowed: No" },
  { name: "Credit cards accepted: VISA, MasterCard, Amex,  Discover Welcomed" },
];
export const Rentals = [
  { name: "Carts: Yes - included in green fees" },
  { name: "Clubs: Yes" },
];
export const Practice = [
  { name: "Driving range: Yes" },
  { name: "Pitching/chipping area: Yes" },
  { name: "Putting green: Yes" },
  { name: "Teaching pro: Yes" },
];

export const hotDeals = [
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
  {
    courseName: "Golf Club St. Leon-Rot",
    details: "Royal Portrush Golf Club - Valley Course",
    url: bgImage,
    rating: 5,
    reviews: 9382,
    rs: [{ price: "8500" }, { price: "8500" }],
    timing: "11:55PM - 11:55PM",
  },
];

export const whyHotDeals = [
  { reason: "Best tee time rates" },
  { reason: "Get free golf faster" },
  { reason: "Exclusively on GolfNow" },
  { reason: "Available at thousands of golf courses" },
  { reason: "100% refundable due to weather closures" },
  { reason: "refundable due to weather closures" },
];

export const whatYouGet = [
  {
    img: play,
    text: "Earn loyalty points to use against your orders.",
  },
  {
    img: fingureIndicator,
    text: "On-line booking amendments.",
  },
  {
    img: golfBoll,
    text: "Save money on your next trip.",
  },
  {
    img: golfStick,
    text: "Receive notification of Promotions.",
  },
];

export const whatYouGetForLogin = [
  {
    img: play,
    text: "Earn loyalty points to use against your orders. ",
  },
  {
    img: fingureIndicator,
    text: "On-line booking amendments. ",
  },
  {
    img: golfBoll,
    text: "Save money on your next trip. ",
  },
  {
    img: golfStick,
    text: "Receive notification of Promotions. ",
  },
];

// ######################################################################################################
export const NEWS_AND_TRENDS = [
  {
    id: 1,
    attributes: {
      title: "Join our golf club and get the best game score",
      description:
        "Get the best game score\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      createdAt: "2023-08-02T11:48:44.293Z",
      updatedAt: "2023-07-28T11:50:43.356Z",
      locale: "en",
      author: null,
      logo: {
        data: {
          id: 22,
          attributes: {
            name: "golf-logo-png-transparent 1.png",
            alternativeText: null,
            caption: null,
            width: 63,
            height: 26,
            formats: null,
            hash: "golf_logo_png_transparent_1_90f6c5e2c8",
            ext: ".png",
            mime: "image/png",
            size: 0.84,
            url: "/uploads/golf_logo_png_transparent_1_90f6c5e2c8.png",
            previewUrl: null,
            provider: "Golf Magazin",
            provider_metadata: null,
            createdAt: "2023-07-28T11:49:44.006Z",
            updatedAt: "2023-07-28T11:49:44.006Z",
          },
        },
      },
      backgroundImage: {
        data: {
          id: 20,
          attributes: {
            name: "image 22.png",
            alternativeText: null,
            caption: null,
            width: 762,
            height: 464,
            formats: {
              small: {
                ext: ".png",
                url: "/uploads/small_image_22_f1bc332cf8.png",
                hash: "small_image_22_f1bc332cf8",
                mime: "image/png",
                name: "small_image 22.png",
                path: null,
                size: 329.43,
                width: 500,
                height: 304,
              },
              medium: {
                ext: ".png",
                url: "/uploads/medium_image_22_f1bc332cf8.png",
                hash: "medium_image_22_f1bc332cf8",
                mime: "image/png",
                name: "medium_image 22.png",
                path: null,
                size: 692.49,
                width: 750,
                height: 457,
              },
              thumbnail: {
                ext: ".png",
                url: "/uploads/thumbnail_image_22_f1bc332cf8.png",
                hash: "thumbnail_image_22_f1bc332cf8",
                mime: "image/png",
                name: "thumbnail_image 22.png",
                path: null,
                size: 85.41,
                width: 245,
                height: 149,
              },
            },
            hash: "image_22_f1bc332cf8",
            ext: ".png",
            mime: "image/png",
            size: 184.11,
            url: "/uploads/image_22_f1bc332cf8.png",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            createdAt: "2023-07-28T11:48:22.014Z",
            updatedAt: "2023-07-28T11:48:22.014Z",
          },
        },
      },
      localizations: {
        data: [],
      },
    },
  },
  {
    id: 1,
    attributes: {
      title: "Join our golf club and get the best game score",
      description:
        "Get the best game score\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      createdAt: "2023-08-02T11:48:44.293Z",
      updatedAt: "2023-07-28T11:50:43.356Z",
      locale: "en",
      author: null,
      logo: {
        data: {
          id: 22,
          attributes: {
            name: "golf-logo-png-transparent 1.png",
            alternativeText: null,
            caption: null,
            width: 63,
            height: 26,
            formats: null,
            hash: "golf_logo_png_transparent_1_90f6c5e2c8",
            ext: ".png",
            mime: "image/png",
            size: 0.84,
            url: "/uploads/golf_logo_png_transparent_1_90f6c5e2c8.png",
            previewUrl: null,
            provider: "Golf Magazin",
            provider_metadata: null,
            createdAt: "2023-07-28T11:49:44.006Z",
            updatedAt: "2023-07-28T11:49:44.006Z",
          },
        },
      },
      backgroundImage: {
        data: {
          id: 20,
          attributes: {
            name: "image 22.png",
            alternativeText: null,
            caption: null,
            width: 762,
            height: 464,
            formats: {
              small: {
                ext: ".png",
                url: "/uploads/small_image_22_f1bc332cf8.png",
                hash: "small_image_22_f1bc332cf8",
                mime: "image/png",
                name: "small_image 22.png",
                path: null,
                size: 329.43,
                width: 500,
                height: 304,
              },
              medium: {
                ext: ".png",
                url: "/uploads/medium_image_22_f1bc332cf8.png",
                hash: "medium_image_22_f1bc332cf8",
                mime: "image/png",
                name: "medium_image 22.png",
                path: null,
                size: 692.49,
                width: 750,
                height: 457,
              },
              thumbnail: {
                ext: ".png",
                url: "/uploads/thumbnail_image_22_f1bc332cf8.png",
                hash: "thumbnail_image_22_f1bc332cf8",
                mime: "image/png",
                name: "thumbnail_image 22.png",
                path: null,
                size: 85.41,
                width: 245,
                height: 149,
              },
            },
            hash: "image_22_f1bc332cf8",
            ext: ".png",
            mime: "image/png",
            size: 184.11,
            url: "/uploads/image_22_f1bc332cf8.png",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            createdAt: "2023-07-28T11:48:22.014Z",
            updatedAt: "2023-07-28T11:48:22.014Z",
          },
        },
      },
      localizations: {
        data: [],
      },
    },
  },
];

export const NEWS_DETAILS = {
  id: 1,
  attributes: {
    title: "Join our golf club and get the best game score",
    description:
      "Get the best game score\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    createdAt: "2023-08-02T11:48:44.293Z",
    updatedAt: "2023-07-28T11:50:43.356Z",
    locale: "en",
    author: null,
    logo: {
      data: {
        id: 22,
        attributes: {
          name: "golf-logo-png-transparent 1.png",
          alternativeText: null,
          caption: null,
          width: 63,
          height: 26,
          formats: null,
          hash: "golf_logo_png_transparent_1_90f6c5e2c8",
          ext: ".png",
          mime: "image/png",
          size: 0.84,
          url: "/uploads/golf_logo_png_transparent_1_90f6c5e2c8.png",
          previewUrl: null,
          provider: "Golf Magazin",
          provider_metadata: null,
          createdAt: "2023-07-28T11:49:44.006Z",
          updatedAt: "2023-07-28T11:49:44.006Z",
        },
      },
    },
    backgroundImage: {
      data: {
        id: 20,
        attributes: {
          name: "image 22.png",
          alternativeText: null,
          caption: null,
          width: 762,
          height: 464,
          formats: {
            small: {
              ext: ".png",
              url: "/uploads/small_image_22_f1bc332cf8.png",
              hash: "small_image_22_f1bc332cf8",
              mime: "image/png",
              name: "small_image 22.png",
              path: null,
              size: 329.43,
              width: 500,
              height: 304,
            },
            medium: {
              ext: ".png",
              url: "/uploads/medium_image_22_f1bc332cf8.png",
              hash: "medium_image_22_f1bc332cf8",
              mime: "image/png",
              name: "medium_image 22.png",
              path: null,
              size: 692.49,
              width: 750,
              height: 457,
            },
            thumbnail: {
              ext: ".png",
              url: "/uploads/thumbnail_image_22_f1bc332cf8.png",
              hash: "thumbnail_image_22_f1bc332cf8",
              mime: "image/png",
              name: "thumbnail_image 22.png",
              path: null,
              size: 85.41,
              width: 245,
              height: 149,
            },
          },
          hash: "image_22_f1bc332cf8",
          ext: ".png",
          mime: "image/png",
          size: 184.11,
          url: "/uploads/image_22_f1bc332cf8.png",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          createdAt: "2023-07-28T11:48:22.014Z",
          updatedAt: "2023-07-28T11:48:22.014Z",
        },
      },
    },
    localizations: {
      data: [],
    },
  },
};

export const COMMENTS = [
  {
    id: "1",
    author: {
      name: "Ashton Porter",
      url: GolfMagazinImg,
    },
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    id: "2",
    author: {
      name: "Ashton Porter",
      url: GolfMagazinImg,
    },
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
];

export const TOTAL_LIKES = 223;
export const TOTAL_COMMENTS = 2;

export const CONTACT_INFO = {
  mail: "Info@yopmail.com",
  contact: "+1 (098) 123-4567",
  support: "Support center",
};
// ######################################################################################################

export const BEST_COURSE_GERMANY_NEW = [
  {
    id: 55,
    name: "Zest demo - GC Dublin",
    description:
      "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
    website: "www.zestfacility.com",
    email: "info6@zestfacility.com",
    timeZone: "Europe/Dublin",
    language: "en",
    currencyCode: "GBP",
    address: {
      mid: 189,
      place: "Dublin",
      state: "",
      region: "",
      country: "Ireland",
      zipcode: "",
      address1: "Golfstreet 1",
      latitude: 53.35698,
      longitude: -6.17057,
    },
    length: "4332",
    par: 23,
    place: "Dublin",
    state: "",
    region: "",
    country: "Ireland",
    zipcode: "",
    address1: "Golfstreet 1",
    createdAt: "2023-07-27T13:50:59.679Z",
    updatedAt: "2023-07-27T13:53:58.370Z",
    image: [
      {
        id: 2,
        name: "147307.jpg",
        alternativeText: null,
        caption: null,
        width: 992,
        height: 558,
        formats: {
          small: {
            ext: ".jpg",
            url: "/uploads/small_147307_5a01379092.jpg",
            hash: "small_147307_5a01379092",
            mime: "image/jpeg",
            name: "small_147307.jpg",
            path: null,
            size: 35.12,
            width: 500,
            height: 281,
          },
          medium: {
            ext: ".jpg",
            url: "/uploads/medium_147307_5a01379092.jpg",
            hash: "medium_147307_5a01379092",
            mime: "image/jpeg",
            name: "medium_147307.jpg",
            path: null,
            size: 74.2,
            width: 750,
            height: 422,
          },
          thumbnail: {
            ext: ".jpg",
            url: "/uploads/thumbnail_147307_5a01379092.jpg",
            hash: "thumbnail_147307_5a01379092",
            mime: "image/jpeg",
            name: "thumbnail_147307.jpg",
            path: null,
            size: 9.85,
            width: 245,
            height: 138,
          },
        },
        hash: "147307_5a01379092",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 129.44,
        url: "/uploads/147307_5a01379092.jpg",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-07-27T13:53:32.808Z",
        updatedAt: "2023-07-27T13:53:32.808Z",
      },
    ],
  },
  {
    id: 50,
    name: "Zest demo - GC Lisbon",
    description:
      "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
    website: "www.zestfacility.com",
    email: "info1@zestfacility.com",
    timeZone: "Europe/Lisbon",
    language: "en",
    currencyCode: "EUR",
    address: {
      mid: 181,
      place: "Lisbon",
      state: "",
      region: "",
      country: "Portugal",
      zipcode: "",
      address1: "Golf street 1",
      latitude: 38.70119,
      longitude: -9.46451,
    },
    length: "432",
    par: 18,
    place: "Lisbon",
    state: "",
    region: "",
    country: "Portugal",
    zipcode: "",
    address1: "Golf street 1",
    createdAt: "2023-07-27T13:52:13.422Z",
    updatedAt: "2023-07-28T11:38:26.165Z",
    image: [
      {
        id: 8,
        name: "photo-1538648759472-7251f7cb2c2f.jpeg",
        alternativeText: null,
        caption: null,
        width: 1000,
        height: 750,
        formats: {
          small: {
            ext: ".jpeg",
            url: "/uploads/small_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
            hash: "small_photo_1538648759472_7251f7cb2c2f_2779340168",
            mime: "image/jpeg",
            name: "small_photo-1538648759472-7251f7cb2c2f.jpeg",
            path: null,
            size: 30.04,
            width: 500,
            height: 375,
          },
          medium: {
            ext: ".jpeg",
            url: "/uploads/medium_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
            hash: "medium_photo_1538648759472_7251f7cb2c2f_2779340168",
            mime: "image/jpeg",
            name: "medium_photo-1538648759472-7251f7cb2c2f.jpeg",
            path: null,
            size: 69.89,
            width: 750,
            height: 563,
          },
          thumbnail: {
            ext: ".jpeg",
            url: "/uploads/thumbnail_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
            hash: "thumbnail_photo_1538648759472_7251f7cb2c2f_2779340168",
            mime: "image/jpeg",
            name: "thumbnail_photo-1538648759472-7251f7cb2c2f.jpeg",
            path: null,
            size: 5.72,
            width: 208,
            height: 156,
          },
        },
        hash: "photo_1538648759472_7251f7cb2c2f_2779340168",
        ext: ".jpeg",
        mime: "image/jpeg",
        size: 120.62,
        url: "/uploads/photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-07-28T11:38:12.878Z",
        updatedAt: "2023-07-28T11:38:12.878Z",
      },
    ],
  },
  {
    id: 56,
    name: "Zest demo - GC Florida",
    description:
      "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
    website: "www.zestfacility.com",
    email: "info7@zestfacility.com",
    timeZone: "America/New_York",
    language: "en",
    currencyCode: "USD",
    address: {
      mid: 191,
      place: "Miami",
      state: "",
      region: "",
      country: "United States",
      zipcode: "",
      address1: "Golf street 1",
      latitude: 28.47763,
      longitude: -81.51332,
    },
    length: "2342",
    par: 34,
    place: "Miami",
    state: "",
    region: "",
    country: "United States",
    zipcode: "",
    address1: "Golf street 1",
    createdAt: "2023-07-27T13:52:28.514Z",
    updatedAt: "2023-07-28T11:39:08.370Z",
    image: [
      {
        id: 9,
        name: "image.jpg",
        alternativeText: null,
        caption: null,
        width: 1920,
        height: 1279,
        formats: {
          large: {
            ext: ".jpg",
            url: "/uploads/large_image_f21d791384.jpg",
            hash: "large_image_f21d791384",
            mime: "image/jpeg",
            name: "large_image.jpg",
            path: null,
            size: 174.54,
            width: 1000,
            height: 666,
          },
          small: {
            ext: ".jpg",
            url: "/uploads/small_image_f21d791384.jpg",
            hash: "small_image_f21d791384",
            mime: "image/jpeg",
            name: "small_image.jpg",
            path: null,
            size: 43.43,
            width: 500,
            height: 333,
          },
          medium: {
            ext: ".jpg",
            url: "/uploads/medium_image_f21d791384.jpg",
            hash: "medium_image_f21d791384",
            mime: "image/jpeg",
            name: "medium_image.jpg",
            path: null,
            size: 97.06,
            width: 750,
            height: 500,
          },
          thumbnail: {
            ext: ".jpg",
            url: "/uploads/thumbnail_image_f21d791384.jpg",
            hash: "thumbnail_image_f21d791384",
            mime: "image/jpeg",
            name: "thumbnail_image.jpg",
            path: null,
            size: 10.76,
            width: 235,
            height: 156,
          },
        },
        hash: "image_f21d791384",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 624.96,
        url: "/uploads/image_f21d791384.jpg",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-07-28T11:39:04.758Z",
        updatedAt: "2023-07-28T11:39:04.758Z",
      },
    ],
  },
  {
    id: 52,
    name: "Zest demo - GC Madrid",
    description:
      "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
    website: "www.zestfacility.com",
    email: "info2@zestfacility.com",
    timeZone: "Europe/Madrid",
    language: "en",
    currencyCode: "EUR",
    address: {
      mid: 183,
      place: "Madrid",
      state: "",
      region: "",
      country: "Spain",
      zipcode: "16350",
      address1: "Golfstreet 1",
      latitude: 40.46493,
      longitude: -3.74015,
    },
    length: "34323",
    par: 32,
    place: "Madrid",
    state: "",
    region: "",
    country: "Spain",
    zipcode: "16350",
    address1: "Golfstreet 1",
    createdAt: "2023-07-27T13:51:05.772Z",
    updatedAt: "2023-07-28T11:39:45.898Z",
    image: [
      {
        id: 10,
        name: "Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
        alternativeText: null,
        caption: null,
        width: 1800,
        height: 886,
        formats: {
          large: {
            ext: ".jpg",
            url: "/uploads/large_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
            hash: "large_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
            mime: "image/jpeg",
            name: "large_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
            path: null,
            size: 112.04,
            width: 1000,
            height: 492,
          },
          small: {
            ext: ".jpg",
            url: "/uploads/small_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
            hash: "small_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
            mime: "image/jpeg",
            name: "small_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
            path: null,
            size: 28.49,
            width: 500,
            height: 246,
          },
          medium: {
            ext: ".jpg",
            url: "/uploads/medium_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
            hash: "medium_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
            mime: "image/jpeg",
            name: "medium_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
            path: null,
            size: 62.13,
            width: 750,
            height: 369,
          },
          thumbnail: {
            ext: ".jpg",
            url: "/uploads/thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
            hash: "thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
            mime: "image/jpeg",
            name: "thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
            path: null,
            size: 7.71,
            width: 245,
            height: 120,
          },
        },
        hash: "Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 419.16,
        url: "/uploads/Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-07-28T11:39:32.085Z",
        updatedAt: "2023-07-28T11:39:32.085Z",
      },
    ],
  },
  {
    id: 53,
    name: "Zest demo - GC Valencia",
    description:
      "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
    website: "www.zestfacility.com",
    email: "info4@zestfacility.com",
    timeZone: "Europe/Madrid",
    language: "en",
    currencyCode: "EUR",
    address: {
      mid: 185,
      place: "Valencia",
      state: "",
      region: "",
      country: "Spain",
      zipcode: "",
      address1: "Golfstreet 1",
      latitude: 39.31715,
      longitude: -0.30108,
    },
    length: "9342",
    par: 23,
    place: "Valencia",
    state: "",
    region: "",
    country: "Spain",
    zipcode: "",
    address1: "Golfstreet 1",
    createdAt: "2023-07-27T13:51:05.772Z",
    updatedAt: "2023-07-28T11:40:19.934Z",
    image: [
      {
        id: 11,
        name: "Blue-Doral-Monster-Hero.jpg",
        alternativeText: null,
        caption: null,
        width: 1800,
        height: 1200,
        formats: {
          large: {
            ext: ".jpg",
            url: "/uploads/large_Blue_Doral_Monster_Hero_bd17a3c1f0.jpg",
            hash: "large_Blue_Doral_Monster_Hero_bd17a3c1f0",
            mime: "image/jpeg",
            name: "large_Blue-Doral-Monster-Hero.jpg",
            path: null,
            size: 101.93,
            width: 1000,
            height: 667,
          },
          small: {
            ext: ".jpg",
            url: "/uploads/small_Blue_Doral_Monster_Hero_bd17a3c1f0.jpg",
            hash: "small_Blue_Doral_Monster_Hero_bd17a3c1f0",
            mime: "image/jpeg",
            name: "small_Blue-Doral-Monster-Hero.jpg",
            path: null,
            size: 30.98,
            width: 500,
            height: 333,
          },
          medium: {
            ext: ".jpg",
            url: "/uploads/medium_Blue_Doral_Monster_Hero_bd17a3c1f0.jpg",
            hash: "medium_Blue_Doral_Monster_Hero_bd17a3c1f0",
            mime: "image/jpeg",
            name: "medium_Blue-Doral-Monster-Hero.jpg",
            path: null,
            size: 63.39,
            width: 750,
            height: 500,
          },
          thumbnail: {
            ext: ".jpg",
            url: "/uploads/thumbnail_Blue_Doral_Monster_Hero_bd17a3c1f0.jpg",
            hash: "thumbnail_Blue_Doral_Monster_Hero_bd17a3c1f0",
            mime: "image/jpeg",
            name: "thumbnail_Blue-Doral-Monster-Hero.jpg",
            path: null,
            size: 7.94,
            width: 234,
            height: 156,
          },
        },
        hash: "Blue_Doral_Monster_Hero_bd17a3c1f0",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 245.27,
        url: "/uploads/Blue_Doral_Monster_Hero_bd17a3c1f0.jpg",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-07-28T11:40:07.347Z",
        updatedAt: "2023-07-28T11:40:07.347Z",
      },
    ],
  },
];

export const BEST_COURSE_GERMANY = {
  data: [
    {
      id: 55,
      attributes: {
        name: "Zest demo - GC Dublin",
        description:
          "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
        website: "www.zestfacility.com",
        email: "info6@zestfacility.com",
        timeZone: "Europe/Dublin",
        language: "en",
        currencyCode: "GBP",
        address: {
          mid: 189,
          place: "Dublin",
          state: "",
          region: "",
          country: "Ireland",
          zipcode: "",
          address1: "Golfstreet 1",
          latitude: 53.35698,
          longitude: -6.17057,
        },
        length: "4332",
        par: 23,
        place: "Dublin",
        state: "",
        region: "",
        country: "Ireland",
        zipcode: "",
        address1: "Golfstreet 1",
        createdAt: "2023-07-27T13:50:59.679Z",
        updatedAt: "2023-07-27T13:53:58.370Z",
        image: {
          data: [
            {
              id: 2,
              attributes: {
                name: "147307.jpg",
                alternativeText: null,
                caption: null,
                width: 992,
                height: 558,
                formats: {
                  small: {
                    ext: ".jpg",
                    url: "/uploads/small_147307_5a01379092.jpg",
                    hash: "small_147307_5a01379092",
                    mime: "image/jpeg",
                    name: "small_147307.jpg",
                    path: null,
                    size: 35.12,
                    width: 500,
                    height: 281,
                  },
                  medium: {
                    ext: ".jpg",
                    url: "/uploads/medium_147307_5a01379092.jpg",
                    hash: "medium_147307_5a01379092",
                    mime: "image/jpeg",
                    name: "medium_147307.jpg",
                    path: null,
                    size: 74.2,
                    width: 750,
                    height: 422,
                  },
                  thumbnail: {
                    ext: ".jpg",
                    url: "/uploads/thumbnail_147307_5a01379092.jpg",
                    hash: "thumbnail_147307_5a01379092",
                    mime: "image/jpeg",
                    name: "thumbnail_147307.jpg",
                    path: null,
                    size: 9.85,
                    width: 245,
                    height: 138,
                  },
                },
                hash: "147307_5a01379092",
                ext: ".jpg",
                mime: "image/jpeg",
                size: 129.44,
                url: clubImg,
                previewUrl: null,
                provider: "local",
                provider_metadata: null,
                createdAt: "2023-07-27T13:53:32.808Z",
                updatedAt: "2023-07-27T13:53:32.808Z",
              },
            },
          ],
        },
      },
    },
    {
      id: 50,
      attributes: {
        name: "Zest demo - GC Lisbon",
        description:
          "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
        website: "www.zestfacility.com",
        email: "info1@zestfacility.com",
        timeZone: "Europe/Lisbon",
        language: "en",
        currencyCode: "EUR",
        address: {
          mid: 181,
          place: "Lisbon",
          state: "",
          region: "",
          country: "Portugal",
          zipcode: "",
          address1: "Golf street 1",
          latitude: 38.70119,
          longitude: -9.46451,
        },
        length: "432",
        par: 18,
        place: "Lisbon",
        state: "",
        region: "",
        country: "Portugal",
        zipcode: "",
        address1: "Golf street 1",
        createdAt: "2023-07-27T13:52:13.422Z",
        updatedAt: "2023-07-28T11:38:26.165Z",
        image: {
          data: [
            {
              id: 8,
              attributes: {
                name: "photo-1538648759472-7251f7cb2c2f.jpeg",
                alternativeText: null,
                caption: null,
                width: 1000,
                height: 750,
                formats: {
                  small: {
                    ext: ".jpeg",
                    url: "/uploads/small_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
                    hash: "small_photo_1538648759472_7251f7cb2c2f_2779340168",
                    mime: "image/jpeg",
                    name: "small_photo-1538648759472-7251f7cb2c2f.jpeg",
                    path: null,
                    size: 30.04,
                    width: 500,
                    height: 375,
                  },
                  medium: {
                    ext: ".jpeg",
                    url: "/uploads/medium_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
                    hash: "medium_photo_1538648759472_7251f7cb2c2f_2779340168",
                    mime: "image/jpeg",
                    name: "medium_photo-1538648759472-7251f7cb2c2f.jpeg",
                    path: null,
                    size: 69.89,
                    width: 750,
                    height: 563,
                  },
                  thumbnail: {
                    ext: ".jpeg",
                    url: "/uploads/thumbnail_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
                    hash: "thumbnail_photo_1538648759472_7251f7cb2c2f_2779340168",
                    mime: "image/jpeg",
                    name: "thumbnail_photo-1538648759472-7251f7cb2c2f.jpeg",
                    path: null,
                    size: 5.72,
                    width: 208,
                    height: 156,
                  },
                },
                hash: "photo_1538648759472_7251f7cb2c2f_2779340168",
                ext: ".jpeg",
                mime: "image/jpeg",
                size: 120.62,
                url: clubImg2,
                previewUrl: null,
                provider: "local",
                provider_metadata: null,
                createdAt: "2023-07-28T11:38:12.878Z",
                updatedAt: "2023-07-28T11:38:12.878Z",
              },
            },
          ],
        },
      },
    },
    {
      id: 56,
      attributes: {
        name: "Zest demo - GC Florida",
        description:
          "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
        website: "www.zestfacility.com",
        email: "info7@zestfacility.com",
        timeZone: "America/New_York",
        language: "en",
        currencyCode: "USD",
        address: {
          mid: 191,
          place: "Miami",
          state: "",
          region: "",
          country: "United States",
          zipcode: "",
          address1: "Golf street 1",
          latitude: 28.47763,
          longitude: -81.51332,
        },
        length: "2342",
        par: 34,
        place: "Miami",
        state: "",
        region: "",
        country: "United States",
        zipcode: "",
        address1: "Golf street 1",
        createdAt: "2023-07-27T13:52:28.514Z",
        updatedAt: "2023-07-28T11:39:08.370Z",
        image: {
          data: [
            {
              id: 9,
              attributes: {
                name: "image.jpg",
                alternativeText: null,
                caption: null,
                width: 1920,
                height: 1279,
                formats: {
                  large: {
                    ext: ".jpg",
                    url: "/uploads/large_image_f21d791384.jpg",
                    hash: "large_image_f21d791384",
                    mime: "image/jpeg",
                    name: "large_image.jpg",
                    path: null,
                    size: 174.54,
                    width: 1000,
                    height: 666,
                  },
                  small: {
                    ext: ".jpg",
                    url: "/uploads/small_image_f21d791384.jpg",
                    hash: "small_image_f21d791384",
                    mime: "image/jpeg",
                    name: "small_image.jpg",
                    path: null,
                    size: 43.43,
                    width: 500,
                    height: 333,
                  },
                  medium: {
                    ext: ".jpg",
                    url: "/uploads/medium_image_f21d791384.jpg",
                    hash: "medium_image_f21d791384",
                    mime: "image/jpeg",
                    name: "medium_image.jpg",
                    path: null,
                    size: 97.06,
                    width: 750,
                    height: 500,
                  },
                  thumbnail: {
                    ext: ".jpg",
                    url: "/uploads/thumbnail_image_f21d791384.jpg",
                    hash: "thumbnail_image_f21d791384",
                    mime: "image/jpeg",
                    name: "thumbnail_image.jpg",
                    path: null,
                    size: 10.76,
                    width: 235,
                    height: 156,
                  },
                },
                hash: "image_f21d791384",
                ext: ".jpg",
                mime: "image/jpeg",
                size: 624.96,
                url: clubImg3,
                previewUrl: null,
                provider: "local",
                provider_metadata: null,
                createdAt: "2023-07-28T11:39:04.758Z",
                updatedAt: "2023-07-28T11:39:04.758Z",
              },
            },
          ],
        },
      },
    },
    {
      id: 52,
      attributes: {
        name: "Zest demo - GC Madrid",
        description:
          "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
        website: "www.zestfacility.com",
        email: "info2@zestfacility.com",
        timeZone: "Europe/Madrid",
        language: "en",
        currencyCode: "EUR",
        address: {
          mid: 183,
          place: "Madrid",
          state: "",
          region: "",
          country: "Spain",
          zipcode: "16350",
          address1: "Golfstreet 1",
          latitude: 40.46493,
          longitude: -3.74015,
        },
        length: "34323",
        par: 32,
        place: "Madrid",
        state: "",
        region: "",
        country: "Spain",
        zipcode: "16350",
        address1: "Golfstreet 1",
        createdAt: "2023-07-27T13:51:05.772Z",
        updatedAt: "2023-07-28T11:39:45.898Z",
        image: {
          data: [
            {
              id: 10,
              attributes: {
                name: "Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
                alternativeText: null,
                caption: null,
                width: 1800,
                height: 886,
                formats: {
                  large: {
                    ext: ".jpg",
                    url: "/uploads/large_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
                    hash: "large_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
                    mime: "image/jpeg",
                    name: "large_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
                    path: null,
                    size: 112.04,
                    width: 1000,
                    height: 492,
                  },
                  small: {
                    ext: ".jpg",
                    url: "/uploads/small_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
                    hash: "small_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
                    mime: "image/jpeg",
                    name: "small_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
                    path: null,
                    size: 28.49,
                    width: 500,
                    height: 246,
                  },
                  medium: {
                    ext: ".jpg",
                    url: "/uploads/medium_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
                    hash: "medium_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
                    mime: "image/jpeg",
                    name: "medium_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
                    path: null,
                    size: 62.13,
                    width: 750,
                    height: 369,
                  },
                  thumbnail: {
                    ext: ".jpg",
                    url: "/uploads/thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
                    hash: "thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
                    mime: "image/jpeg",
                    name: "thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
                    path: null,
                    size: 7.71,
                    width: 245,
                    height: 120,
                  },
                },
                hash: "Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
                ext: ".jpg",
                mime: "image/jpeg",
                size: 419.16,
                url: clubImg4,
                previewUrl: null,
                provider: "local",
                provider_metadata: null,
                createdAt: "2023-07-28T11:39:32.085Z",
                updatedAt: "2023-07-28T11:39:32.085Z",
              },
            },
          ],
        },
      },
    },
    {
      id: 55,
      attributes: {
        name: "Zest demo - GC Dublin",
        description:
          "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
        website: "www.zestfacility.com",
        email: "info6@zestfacility.com",
        timeZone: "Europe/Dublin",
        language: "en",
        currencyCode: "GBP",
        address: {
          mid: 189,
          place: "Dublin",
          state: "",
          region: "",
          country: "Ireland",
          zipcode: "",
          address1: "Golfstreet 1",
          latitude: 53.35698,
          longitude: -6.17057,
        },
        length: "4332",
        par: 23,
        place: "Dublin",
        state: "",
        region: "",
        country: "Ireland",
        zipcode: "",
        address1: "Golfstreet 1",
        createdAt: "2023-07-27T13:50:59.679Z",
        updatedAt: "2023-07-27T13:53:58.370Z",
        image: {
          data: [
            {
              id: 2,
              attributes: {
                name: "147307.jpg",
                alternativeText: null,
                caption: null,
                width: 992,
                height: 558,
                formats: {
                  small: {
                    ext: ".jpg",
                    url: "/uploads/small_147307_5a01379092.jpg",
                    hash: "small_147307_5a01379092",
                    mime: "image/jpeg",
                    name: "small_147307.jpg",
                    path: null,
                    size: 35.12,
                    width: 500,
                    height: 281,
                  },
                  medium: {
                    ext: ".jpg",
                    url: "/uploads/medium_147307_5a01379092.jpg",
                    hash: "medium_147307_5a01379092",
                    mime: "image/jpeg",
                    name: "medium_147307.jpg",
                    path: null,
                    size: 74.2,
                    width: 750,
                    height: 422,
                  },
                  thumbnail: {
                    ext: ".jpg",
                    url: "/uploads/thumbnail_147307_5a01379092.jpg",
                    hash: "thumbnail_147307_5a01379092",
                    mime: "image/jpeg",
                    name: "thumbnail_147307.jpg",
                    path: null,
                    size: 9.85,
                    width: 245,
                    height: 138,
                  },
                },
                hash: "147307_5a01379092",
                ext: ".jpg",
                mime: "image/jpeg",
                size: 129.44,
                url: clubImg,
                previewUrl: null,
                provider: "local",
                provider_metadata: null,
                createdAt: "2023-07-27T13:53:32.808Z",
                updatedAt: "2023-07-27T13:53:32.808Z",
              },
            },
          ],
        },
      },
    },
    {
      id: 50,
      attributes: {
        name: "Zest demo - GC Lisbon",
        description:
          "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
        website: "www.zestfacility.com",
        email: "info1@zestfacility.com",
        timeZone: "Europe/Lisbon",
        language: "en",
        currencyCode: "EUR",
        address: {
          mid: 181,
          place: "Lisbon",
          state: "",
          region: "",
          country: "Portugal",
          zipcode: "",
          address1: "Golf street 1",
          latitude: 38.70119,
          longitude: -9.46451,
        },
        length: "432",
        par: 18,
        place: "Lisbon",
        state: "",
        region: "",
        country: "Portugal",
        zipcode: "",
        address1: "Golf street 1",
        createdAt: "2023-07-27T13:52:13.422Z",
        updatedAt: "2023-07-28T11:38:26.165Z",
        image: {
          data: [
            {
              id: 8,
              attributes: {
                name: "photo-1538648759472-7251f7cb2c2f.jpeg",
                alternativeText: null,
                caption: null,
                width: 1000,
                height: 750,
                formats: {
                  small: {
                    ext: ".jpeg",
                    url: "/uploads/small_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
                    hash: "small_photo_1538648759472_7251f7cb2c2f_2779340168",
                    mime: "image/jpeg",
                    name: "small_photo-1538648759472-7251f7cb2c2f.jpeg",
                    path: null,
                    size: 30.04,
                    width: 500,
                    height: 375,
                  },
                  medium: {
                    ext: ".jpeg",
                    url: "/uploads/medium_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
                    hash: "medium_photo_1538648759472_7251f7cb2c2f_2779340168",
                    mime: "image/jpeg",
                    name: "medium_photo-1538648759472-7251f7cb2c2f.jpeg",
                    path: null,
                    size: 69.89,
                    width: 750,
                    height: 563,
                  },
                  thumbnail: {
                    ext: ".jpeg",
                    url: "/uploads/thumbnail_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
                    hash: "thumbnail_photo_1538648759472_7251f7cb2c2f_2779340168",
                    mime: "image/jpeg",
                    name: "thumbnail_photo-1538648759472-7251f7cb2c2f.jpeg",
                    path: null,
                    size: 5.72,
                    width: 208,
                    height: 156,
                  },
                },
                hash: "photo_1538648759472_7251f7cb2c2f_2779340168",
                ext: ".jpeg",
                mime: "image/jpeg",
                size: 120.62,
                url: clubImg2,
                previewUrl: null,
                provider: "local",
                provider_metadata: null,
                createdAt: "2023-07-28T11:38:12.878Z",
                updatedAt: "2023-07-28T11:38:12.878Z",
              },
            },
          ],
        },
      },
    },
    {
      id: 56,
      attributes: {
        name: "Zest demo - GC Florida",
        description:
          "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
        website: "www.zestfacility.com",
        email: "info7@zestfacility.com",
        timeZone: "America/New_York",
        language: "en",
        currencyCode: "USD",
        address: {
          mid: 191,
          place: "Miami",
          state: "",
          region: "",
          country: "United States",
          zipcode: "",
          address1: "Golf street 1",
          latitude: 28.47763,
          longitude: -81.51332,
        },
        length: "2342",
        par: 34,
        place: "Miami",
        state: "",
        region: "",
        country: "United States",
        zipcode: "",
        address1: "Golf street 1",
        createdAt: "2023-07-27T13:52:28.514Z",
        updatedAt: "2023-07-28T11:39:08.370Z",
        image: {
          data: [
            {
              id: 9,
              attributes: {
                name: "image.jpg",
                alternativeText: null,
                caption: null,
                width: 1920,
                height: 1279,
                formats: {
                  large: {
                    ext: ".jpg",
                    url: "/uploads/large_image_f21d791384.jpg",
                    hash: "large_image_f21d791384",
                    mime: "image/jpeg",
                    name: "large_image.jpg",
                    path: null,
                    size: 174.54,
                    width: 1000,
                    height: 666,
                  },
                  small: {
                    ext: ".jpg",
                    url: "/uploads/small_image_f21d791384.jpg",
                    hash: "small_image_f21d791384",
                    mime: "image/jpeg",
                    name: "small_image.jpg",
                    path: null,
                    size: 43.43,
                    width: 500,
                    height: 333,
                  },
                  medium: {
                    ext: ".jpg",
                    url: "/uploads/medium_image_f21d791384.jpg",
                    hash: "medium_image_f21d791384",
                    mime: "image/jpeg",
                    name: "medium_image.jpg",
                    path: null,
                    size: 97.06,
                    width: 750,
                    height: 500,
                  },
                  thumbnail: {
                    ext: ".jpg",
                    url: "/uploads/thumbnail_image_f21d791384.jpg",
                    hash: "thumbnail_image_f21d791384",
                    mime: "image/jpeg",
                    name: "thumbnail_image.jpg",
                    path: null,
                    size: 10.76,
                    width: 235,
                    height: 156,
                  },
                },
                hash: "image_f21d791384",
                ext: ".jpg",
                mime: "image/jpeg",
                size: 624.96,
                url: clubImg3,
                previewUrl: null,
                provider: "local",
                provider_metadata: null,
                createdAt: "2023-07-28T11:39:04.758Z",
                updatedAt: "2023-07-28T11:39:04.758Z",
              },
            },
          ],
        },
      },
    },
    {
      id: 52,
      attributes: {
        name: "Zest demo - GC Madrid",
        description:
          "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
        website: "www.zestfacility.com",
        email: "info2@zestfacility.com",
        timeZone: "Europe/Madrid",
        language: "en",
        currencyCode: "EUR",
        address: {
          mid: 183,
          place: "Madrid",
          state: "",
          region: "",
          country: "Spain",
          zipcode: "16350",
          address1: "Golfstreet 1",
          latitude: 40.46493,
          longitude: -3.74015,
        },
        length: "34323",
        par: 32,
        place: "Madrid",
        state: "",
        region: "",
        country: "Spain",
        zipcode: "16350",
        address1: "Golfstreet 1",
        createdAt: "2023-07-27T13:51:05.772Z",
        updatedAt: "2023-07-28T11:39:45.898Z",
        image: {
          data: [
            {
              id: 10,
              attributes: {
                name: "Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
                alternativeText: null,
                caption: null,
                width: 1800,
                height: 886,
                formats: {
                  large: {
                    ext: ".jpg",
                    url: "/uploads/large_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
                    hash: "large_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
                    mime: "image/jpeg",
                    name: "large_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
                    path: null,
                    size: 112.04,
                    width: 1000,
                    height: 492,
                  },
                  small: {
                    ext: ".jpg",
                    url: "/uploads/small_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
                    hash: "small_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
                    mime: "image/jpeg",
                    name: "small_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
                    path: null,
                    size: 28.49,
                    width: 500,
                    height: 246,
                  },
                  medium: {
                    ext: ".jpg",
                    url: "/uploads/medium_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
                    hash: "medium_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
                    mime: "image/jpeg",
                    name: "medium_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
                    path: null,
                    size: 62.13,
                    width: 750,
                    height: 369,
                  },
                  thumbnail: {
                    ext: ".jpg",
                    url: "/uploads/thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
                    hash: "thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
                    mime: "image/jpeg",
                    name: "thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
                    path: null,
                    size: 7.71,
                    width: 245,
                    height: 120,
                  },
                },
                hash: "Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
                ext: ".jpg",
                mime: "image/jpeg",
                size: 419.16,
                url: clubImg4,
                previewUrl: null,
                provider: "local",
                provider_metadata: null,
                createdAt: "2023-07-28T11:39:32.085Z",
                updatedAt: "2023-07-28T11:39:32.085Z",
              },
            },
          ],
        },
      },
    },
  ],
};

export const TODAY_BEST_DEALS = [
  {
    id: 55,
    name: "Zest demo - GC Dublin",
    description:
      "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
    website: "www.zestfacility.com",
    email: "info6@zestfacility.com",
    timeZone: "Europe/Dublin",
    language: "en",
    currencyCode: "GBP",
    address: {
      mid: 189,
      place: "Dublin",
      state: "",
      region: "",
      country: "Ireland",
      zipcode: "",
      address1: "Golfstreet 1",
      latitude: 53.35698,
      longitude: -6.17057,
    },
    length: "4332",
    par: 23,
    place: "Dublin",
    state: "",
    region: "",
    country: "Ireland",
    zipcode: "",
    address1: "Golfstreet 1",
    createdAt: "2023-07-27T13:50:59.679Z",
    updatedAt: "2023-07-27T13:53:58.370Z",
    image: [
      {
        id: 2,
        name: "147307.jpg",
        alternativeText: null,
        caption: null,
        width: 992,
        height: 558,
        formats: {
          small: {
            ext: ".jpg",
            url: "/uploads/small_147307_5a01379092.jpg",
            hash: "small_147307_5a01379092",
            mime: "image/jpeg",
            name: "small_147307.jpg",
            path: null,
            size: 35.12,
            width: 500,
            height: 281,
          },
          medium: {
            ext: ".jpg",
            url: "/uploads/medium_147307_5a01379092.jpg",
            hash: "medium_147307_5a01379092",
            mime: "image/jpeg",
            name: "medium_147307.jpg",
            path: null,
            size: 74.2,
            width: 750,
            height: 422,
          },
          thumbnail: {
            ext: ".jpg",
            url: "/uploads/thumbnail_147307_5a01379092.jpg",
            hash: "thumbnail_147307_5a01379092",
            mime: "image/jpeg",
            name: "thumbnail_147307.jpg",
            path: null,
            size: 9.85,
            width: 245,
            height: 138,
          },
        },
        hash: "147307_5a01379092",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 129.44,
        url: "/uploads/147307_5a01379092.jpg",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-07-27T13:53:32.808Z",
        updatedAt: "2023-07-27T13:53:32.808Z",
      },
    ],
  },
  {
    id: 50,
    name: "Zest demo - GC Lisbon",
    description:
      "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
    website: "www.zestfacility.com",
    email: "info1@zestfacility.com",
    timeZone: "Europe/Lisbon",
    language: "en",
    currencyCode: "EUR",
    address: {
      mid: 181,
      place: "Lisbon",
      state: "",
      region: "",
      country: "Portugal",
      zipcode: "",
      address1: "Golf street 1",
      latitude: 38.70119,
      longitude: -9.46451,
    },
    length: "432",
    par: 18,
    place: "Lisbon",
    state: "",
    region: "",
    country: "Portugal",
    zipcode: "",
    address1: "Golf street 1",
    createdAt: "2023-07-27T13:52:13.422Z",
    updatedAt: "2023-07-28T11:38:26.165Z",
    image: [
      {
        id: 8,
        name: "photo-1538648759472-7251f7cb2c2f.jpeg",
        alternativeText: null,
        caption: null,
        width: 1000,
        height: 750,
        formats: {
          small: {
            ext: ".jpeg",
            url: "/uploads/small_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
            hash: "small_photo_1538648759472_7251f7cb2c2f_2779340168",
            mime: "image/jpeg",
            name: "small_photo-1538648759472-7251f7cb2c2f.jpeg",
            path: null,
            size: 30.04,
            width: 500,
            height: 375,
          },
          medium: {
            ext: ".jpeg",
            url: "/uploads/medium_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
            hash: "medium_photo_1538648759472_7251f7cb2c2f_2779340168",
            mime: "image/jpeg",
            name: "medium_photo-1538648759472-7251f7cb2c2f.jpeg",
            path: null,
            size: 69.89,
            width: 750,
            height: 563,
          },
          thumbnail: {
            ext: ".jpeg",
            url: "/uploads/thumbnail_photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
            hash: "thumbnail_photo_1538648759472_7251f7cb2c2f_2779340168",
            mime: "image/jpeg",
            name: "thumbnail_photo-1538648759472-7251f7cb2c2f.jpeg",
            path: null,
            size: 5.72,
            width: 208,
            height: 156,
          },
        },
        hash: "photo_1538648759472_7251f7cb2c2f_2779340168",
        ext: ".jpeg",
        mime: "image/jpeg",
        size: 120.62,
        url: "/uploads/photo_1538648759472_7251f7cb2c2f_2779340168.jpeg",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-07-28T11:38:12.878Z",
        updatedAt: "2023-07-28T11:38:12.878Z",
      },
    ],
  },
  {
    id: 56,
    name: "Zest demo - GC Florida",
    description:
      "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
    website: "www.zestfacility.com",
    email: "info7@zestfacility.com",
    timeZone: "America/New_York",
    language: "en",
    currencyCode: "USD",
    address: {
      mid: 191,
      place: "Miami",
      state: "",
      region: "",
      country: "United States",
      zipcode: "",
      address1: "Golf street 1",
      latitude: 28.47763,
      longitude: -81.51332,
    },
    length: "2342",
    par: 34,
    place: "Miami",
    state: "",
    region: "",
    country: "United States",
    zipcode: "",
    address1: "Golf street 1",
    createdAt: "2023-07-27T13:52:28.514Z",
    updatedAt: "2023-07-28T11:39:08.370Z",
    image: [
      {
        id: 9,
        name: "image.jpg",
        alternativeText: null,
        caption: null,
        width: 1920,
        height: 1279,
        formats: {
          large: {
            ext: ".jpg",
            url: "/uploads/large_image_f21d791384.jpg",
            hash: "large_image_f21d791384",
            mime: "image/jpeg",
            name: "large_image.jpg",
            path: null,
            size: 174.54,
            width: 1000,
            height: 666,
          },
          small: {
            ext: ".jpg",
            url: "/uploads/small_image_f21d791384.jpg",
            hash: "small_image_f21d791384",
            mime: "image/jpeg",
            name: "small_image.jpg",
            path: null,
            size: 43.43,
            width: 500,
            height: 333,
          },
          medium: {
            ext: ".jpg",
            url: "/uploads/medium_image_f21d791384.jpg",
            hash: "medium_image_f21d791384",
            mime: "image/jpeg",
            name: "medium_image.jpg",
            path: null,
            size: 97.06,
            width: 750,
            height: 500,
          },
          thumbnail: {
            ext: ".jpg",
            url: "/uploads/thumbnail_image_f21d791384.jpg",
            hash: "thumbnail_image_f21d791384",
            mime: "image/jpeg",
            name: "thumbnail_image.jpg",
            path: null,
            size: 10.76,
            width: 235,
            height: 156,
          },
        },
        hash: "image_f21d791384",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 624.96,
        url: "/uploads/image_f21d791384.jpg",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-07-28T11:39:04.758Z",
        updatedAt: "2023-07-28T11:39:04.758Z",
      },
    ],
  },
  {
    id: 52,
    name: "Zest demo - GC Madrid",
    description:
      "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
    website: "www.zestfacility.com",
    email: "info2@zestfacility.com",
    timeZone: "Europe/Madrid",
    language: "en",
    currencyCode: "EUR",
    address: {
      mid: 183,
      place: "Madrid",
      state: "",
      region: "",
      country: "Spain",
      zipcode: "16350",
      address1: "Golfstreet 1",
      latitude: 40.46493,
      longitude: -3.74015,
    },
    length: "34323",
    par: 32,
    place: "Madrid",
    state: "",
    region: "",
    country: "Spain",
    zipcode: "16350",
    address1: "Golfstreet 1",
    createdAt: "2023-07-27T13:51:05.772Z",
    updatedAt: "2023-07-28T11:39:45.898Z",
    image: [
      {
        id: 10,
        name: "Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
        alternativeText: null,
        caption: null,
        width: 1800,
        height: 886,
        formats: {
          large: {
            ext: ".jpg",
            url: "/uploads/large_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
            hash: "large_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
            mime: "image/jpeg",
            name: "large_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
            path: null,
            size: 112.04,
            width: 1000,
            height: 492,
          },
          small: {
            ext: ".jpg",
            url: "/uploads/small_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
            hash: "small_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
            mime: "image/jpeg",
            name: "small_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
            path: null,
            size: 28.49,
            width: 500,
            height: 246,
          },
          medium: {
            ext: ".jpg",
            url: "/uploads/medium_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
            hash: "medium_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
            mime: "image/jpeg",
            name: "medium_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
            path: null,
            size: 62.13,
            width: 750,
            height: 369,
          },
          thumbnail: {
            ext: ".jpg",
            url: "/uploads/thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
            hash: "thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
            mime: "image/jpeg",
            name: "thumbnail_Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg",
            path: null,
            size: 7.71,
            width: 245,
            height: 120,
          },
        },
        hash: "Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 419.16,
        url: "/uploads/Great_Waters_at_Reynolds_Lake_Oconee_Oct_2019_8094e183b6.jpg",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-07-28T11:39:32.085Z",
        updatedAt: "2023-07-28T11:39:32.085Z",
      },
    ],
  },
  {
    id: 53,
    name: "Zest demo - GC Valencia",
    description:
      "Panther Lake Orange County National is an 9-hole course situated far from residential communities.",
    website: "www.zestfacility.com",
    email: "info4@zestfacility.com",
    timeZone: "Europe/Madrid",
    language: "en",
    currencyCode: "EUR",
    address: {
      mid: 185,
      place: "Valencia",
      state: "",
      region: "",
      country: "Spain",
      zipcode: "",
      address1: "Golfstreet 1",
      latitude: 39.31715,
      longitude: -0.30108,
    },
    length: "9342",
    par: 23,
    place: "Valencia",
    state: "",
    region: "",
    country: "Spain",
    zipcode: "",
    address1: "Golfstreet 1",
    createdAt: "2023-07-27T13:51:05.772Z",
    updatedAt: "2023-07-28T11:40:19.934Z",
    image: [
      {
        id: 11,
        name: "Blue-Doral-Monster-Hero.jpg",
        alternativeText: null,
        caption: null,
        width: 1800,
        height: 1200,
        formats: {
          large: {
            ext: ".jpg",
            url: "/uploads/large_Blue_Doral_Monster_Hero_bd17a3c1f0.jpg",
            hash: "large_Blue_Doral_Monster_Hero_bd17a3c1f0",
            mime: "image/jpeg",
            name: "large_Blue-Doral-Monster-Hero.jpg",
            path: null,
            size: 101.93,
            width: 1000,
            height: 667,
          },
          small: {
            ext: ".jpg",
            url: "/uploads/small_Blue_Doral_Monster_Hero_bd17a3c1f0.jpg",
            hash: "small_Blue_Doral_Monster_Hero_bd17a3c1f0",
            mime: "image/jpeg",
            name: "small_Blue-Doral-Monster-Hero.jpg",
            path: null,
            size: 30.98,
            width: 500,
            height: 333,
          },
          medium: {
            ext: ".jpg",
            url: "/uploads/medium_Blue_Doral_Monster_Hero_bd17a3c1f0.jpg",
            hash: "medium_Blue_Doral_Monster_Hero_bd17a3c1f0",
            mime: "image/jpeg",
            name: "medium_Blue-Doral-Monster-Hero.jpg",
            path: null,
            size: 63.39,
            width: 750,
            height: 500,
          },
          thumbnail: {
            ext: ".jpg",
            url: "/uploads/thumbnail_Blue_Doral_Monster_Hero_bd17a3c1f0.jpg",
            hash: "thumbnail_Blue_Doral_Monster_Hero_bd17a3c1f0",
            mime: "image/jpeg",
            name: "thumbnail_Blue-Doral-Monster-Hero.jpg",
            path: null,
            size: 7.94,
            width: 234,
            height: 156,
          },
        },
        hash: "Blue_Doral_Monster_Hero_bd17a3c1f0",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 245.27,
        url: "/uploads/Blue_Doral_Monster_Hero_bd17a3c1f0.jpg",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-07-28T11:40:07.347Z",
        updatedAt: "2023-07-28T11:40:07.347Z",
      },
    ],
  },
];

export const TEE_TIME_DATA = [
  {
    time: "2024-04-30T08:00:00",
    holes: 18,
    teeid: 123840,
    course: "18 holes",
    products: [
      {
        mid: 198,
        name: "Tushar Product",
        players: null,
        pricing: [
          {
            price: {
              amount: 80,
              currency: "EUR",
            },
            netRate: {
              amount: 72,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 90,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 320,
              currency: "EUR",
            },
            netRate: {
              amount: 288,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 320,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
      {
        mid: 230,
        name: "Product Play 3",
        players: null,
        pricing: [
          {
            price: {
              amount: 80,
              currency: "EUR",
            },
            netRate: {
              amount: 72,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 80,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 320,
              currency: "EUR",
            },
            netRate: {
              amount: 288,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 320,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
      {
        mid: 265,
        name: "player wise product",
        players: null,
        pricing: [
          {
            price: {
              amount: 100,
              currency: "EUR",
            },
            netRate: {
              amount: 90,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 100,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 210,
              currency: "EUR",
            },
            netRate: {
              amount: 189,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 210,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
    ],
    extraProducts: [],
  },
  {
    time: "2024-04-30T08:30:00",
    holes: 18,
    teeid: 123841,
    course: "18 holes",
    products: [
      {
        mid: 198,
        name: "Tushar Product",
        players: null,
        pricing: [
          {
            price: {
              amount: 80,
              currency: "EUR",
            },
            netRate: {
              amount: 72,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 100,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 320,
              currency: "EUR",
            },
            netRate: {
              amount: 288,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 320,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
      {
        mid: 230,
        name: "Product Play 3",
        players: null,
        pricing: [
          {
            price: {
              amount: 80,
              currency: "EUR",
            },
            netRate: {
              amount: 72,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 80,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 320,
              currency: "EUR",
            },
            netRate: {
              amount: 288,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 320,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
      {
        mid: 265,
        name: "player wise product",
        players: null,
        pricing: [
          {
            price: {
              amount: 100,
              currency: "EUR",
            },
            netRate: {
              amount: 90,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 100,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 210,
              currency: "EUR",
            },
            netRate: {
              amount: 189,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 210,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
    ],
    extraProducts: [],
  },
  {
    time: "2024-04-30T09:00:00",
    holes: 18,
    teeid: 123842,
    course: "18 holes",
    products: [
      {
        mid: 198,
        name: "Tushar Product",
        players: null,
        pricing: [
          {
            price: {
              amount: 80,
              currency: "EUR",
            },
            netRate: {
              amount: 72,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 80,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 320,
              currency: "EUR",
            },
            netRate: {
              amount: 288,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 320,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
      {
        mid: 230,
        name: "Product Play 3",
        players: null,
        pricing: [
          {
            price: {
              amount: 80,
              currency: "EUR",
            },
            netRate: {
              amount: 72,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 80,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 320,
              currency: "EUR",
            },
            netRate: {
              amount: 288,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 320,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
      {
        mid: 265,
        name: "player wise product",
        players: null,
        pricing: [
          {
            price: {
              amount: 100,
              currency: "EUR",
            },
            netRate: {
              amount: 90,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 100,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 210,
              currency: "EUR",
            },
            netRate: {
              amount: 189,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 210,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
    ],
    extraProducts: [],
  },
  {
    time: "2024-04-30T09:30:00",
    holes: 18,
    teeid: 123843,
    course: "18 holes",
    products: [
      {
        mid: 198,
        name: "Tushar Product",
        players: null,
        pricing: [
          {
            price: {
              amount: 80,
              currency: "EUR",
            },
            netRate: {
              amount: 72,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 80,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 320,
              currency: "EUR",
            },
            netRate: {
              amount: 288,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 320,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
      {
        mid: 230,
        name: "Product Play 3",
        players: null,
        pricing: [
          {
            price: {
              amount: 80,
              currency: "EUR",
            },
            netRate: {
              amount: 72,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 80,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 320,
              currency: "EUR",
            },
            netRate: {
              amount: 288,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 320,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
      {
        mid: 265,
        name: "player wise product",
        players: null,
        pricing: [
          {
            price: {
              amount: 100,
              currency: "EUR",
            },
            netRate: {
              amount: 90,
              currency: "EUR",
            },
            quantity: 1,
            publicRate: {
              amount: 100,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 160,
              currency: "EUR",
            },
            netRate: {
              amount: 144,
              currency: "EUR",
            },
            quantity: 2,
            publicRate: {
              amount: 160,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 210,
              currency: "EUR",
            },
            netRate: {
              amount: 189,
              currency: "EUR",
            },
            quantity: 3,
            publicRate: {
              amount: 210,
              currency: "EUR",
            },
          },
          {
            price: {
              amount: 240,
              currency: "EUR",
            },
            netRate: {
              amount: 216,
              currency: "EUR",
            },
            quantity: 4,
            publicRate: {
              amount: 240,
              currency: "EUR",
            },
          },
        ],
        category: "Greenfee",
        available: 4,
        linkedProducts: [],
      },
    ],
    extraProducts: [],
  },
];

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import store from "@redux/store";
import posthog from "posthog-js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import common_en from "./translations/en/language.json";
import common_ge from "./translations/ge/language.json";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

// const api_Key = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;

// posthog.init(api_Key, {
//   api_host: "https://us.i.posthog.com",
//   person_profiles: "identified_only",
// });

const root = ReactDOM.createRoot(document.getElementById("root"));

i18next.init({
  lng: "en",
  debug: false,
  resources: {
    en: {
      common: common_en,
    },
    de: {
      common: common_ge,
    },
  },
});

root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
        <Suspense fallback={false}>
          <App />
          <ToastContainer stacked />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

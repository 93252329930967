import newsReducer from "@redux/news/slice";
import userReducer from "@redux/user/slice";
import searchReducer from "@redux/search/slice";
import headerReducer from "@redux/header/slice";
import teetimeReducer from "@redux/teetime/slice";
import bookingReducer from "@redux/booking/slice";
import updateUserReducer from "@redux/user/slice";
import settingsReducer from "@redux/settings/slice";
import authReducer from "@redux/authentication/slice";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  userReducer,
  newsReducer,
  authReducer,
  searchReducer,
  headerReducer,
  teetimeReducer,
  bookingReducer,
  settingsReducer,
  updateUserReducer,
});

export default rootReducer;

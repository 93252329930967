export const areCurrenciesSame = (cartDetails) => {
  let details = [...cartDetails];
  const uniqueCurrencies = new Set();

  for (const transaction of details) {
    uniqueCurrencies.add(transaction.currency);
  }

  return uniqueCurrencies.size === 1 && uniqueCurrencies.has("EUR");
};

import React from "react";
import tw from "tailwind-styled-components";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Zoom } from "@mui/material";
import "./style.css";

function Modal(props) {
  return (
    <div>
      <Dialog
        open={props.openModal || false}
        TransitionComponent={Zoom}
        onClose={props.closeModal}
        maxWidth={"md"}
        fullWidth={props.fullSize}
      >
        <ContainerInner className="shadow-none drop-shadow-none">
          <div className="w-full h-auto flex flex-row justify-between items-start px-2 bg-[white] rounded-t-lg">
            <p className={`${titleTxt} ${props.titleStyle}`}>{props?.title}</p>
            <CloseBtn>
              <IconButton
                onClick={props.closeModal}
                sx={{
                  backgroundColor: "#f8bc04",
                  ":hover": { backgroundColor: "#ffcb2a" },
                }}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </CloseBtn>
          </div>
          {props.children}
        </ContainerInner>
      </Dialog>
    </div>
  );
}

const ContainerInner = tw.div`
w-full 
h-full 
whitespace-pre-line 
flex 
flex-col 
p-[10px]
custom-scroll 
overflow-auto
items-center
`;
const titleTxt = `
pt-1
md:text-2xl sm:text-2xl text-2xl 
text-[black]
font-semibold
mb-2
`;
const CloseBtn = tw.div`
w-[50px] 
h-[50px] 
mr-[-16px]
mt-[-10px] 
flex 
items-start 
justify-end
`;
export default Modal;

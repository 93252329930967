import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { Zoom } from "@mui/material";

function BookingAlertMOdal(props) {
  const { t } = useTranslation("common");
  return (
    <Dialog
      fullScreen
      open={props.openModal}
      onClose={props.closeModal}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <div className="bg-[#464646ba] py-6 pt-40 px-4 w-full h-full d-flex justify-center items-center gap-2">
        <div className="w-full h-[80px] flex justify-center items-center">
          <svg className="w-16 h-16 bg-transparent border-4 animate-spin rounded-full border-t-[white] border-primary" />
        </div>
        <div className="text-center">
          <p className="text-white text-[19px]">
            {t("checkout.boking_waiting")}
          </p>
        </div>
      </div>
    </Dialog>
  );
}

function EmptyCartModal(props) {
  const { t } = useTranslation("common");
  return (
    <Dialog
      open={props.openModal}
      // onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      <div className="bg-white py-8 px-6 w-full h-full flex justify-center items-center gap-4 flex-col text-center">
        <div className="text-center">
          <p className="text-black text-[21px]">{props.errorMessage}</p>
        </div>
        <button
          onClick={props.closeModal}
          className="px-2 bg-primary hover:bg-primary2 text-white font-semibold py-2 rounded-md"
        >
          {t("checkout.go_back")}
        </button>
      </div>
    </Dialog>
  );
}

export { BookingAlertMOdal, EmptyCartModal };

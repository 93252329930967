import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import tw from "tailwind-styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import { newsLetter } from "@redux/news/actions";
import { Input, Button, Text } from "@components";

const FooterText = (props) => {
  return (
    <div className={footerText}>
      <ArrowForwardIosIcon
        sx={{ color: "#FFBC00", height: "12px", width: "12px" }}
      />
      <Text className={text}>{props.children}</Text>
    </div>
  );
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffff",
    color: "rgba(0, 0, 0, 0.6)",
    maxWidth: 220,
    fontSize: 14,
    border: "0.5px solid rgb(0,0,0)",
    borderRadius: 10,
  },
  "& .MuiTooltip-arrow": {
    color: "#ffff",
  },
}));

const styles = {
  footerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "full",
    height: "auto",
    backgroundColor: "black",
  },
  mainView: {
    width: "100%",
    maxWidth: "full",
    height: "auto",
  },
};

const Footer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const [state, setState] = useState({
    email: "",
    postalCode: "",
    loading: false,
  });

  const data = useSelector((state) => state?.headerReducer);
  const FooterNav = data?.menus?.data?.filter(
    (val, ind) => val?.attributes?.slug !== "header-nav"
  );

  const subscribe = () => {
    setState((prev) => ({ ...prev, loading: true }));
    dispatch(
      newsLetter({
        data: {
          email: state.email,
          postalCode: state.postalCode,
        },
      })
    ).then((res) => {
      setState((prev) => ({ ...prev, loading: false }));
      if (res?.payload?.data && res?.payload?.status === 200) {
        setState((prev) => ({ ...prev, email: "", postalCode: "" }));
        toast.warning(t("search.subscribe"), {
          icon: <CheckCircleIcon className="text-primary" />,
        });
      } else {
        setState((prev) => ({ ...prev, email: "", postalCode: "" }));
        toast.error(
          res?.payload?.response?.data?.error?.message ||
            "Internal error occurred"
        );
      }
    });
  };

  return (
    <div style={styles.footerContainer}>
      <div style={styles.mainView}>
        <div className={`container mx-auto ${FooterContainer}`}>
          {FooterNav?.map((item, index) => {
            const FooterNav = item?.attributes?.items?.data;
            return (
              <FooterDiv key={index}>
                <FooterTitle>{item?.attributes?.title}</FooterTitle>
                {FooterNav.map((items, key) => {
                  return (
                    // <a
                    //   href={items?.attributes?.url}
                    //   target={toString(item?.attributes?.target)}
                    //   key={key}
                    // >
                    //   <FooterText>{items?.attributes?.title}</FooterText>
                    // </a>
                    <NavLink
                      key={items?.attributes?.title}
                      to={items?.attributes?.url}
                      target={item?.attributes?.target}
                    >
                      <FooterText>{items?.attributes?.title}</FooterText>
                    </NavLink>
                  );
                })}
              </FooterDiv>
            );
          })}

          {FooterNav && (
            <FooterDiv className="max-w-[330px]">
              <FooterTitle>{t("footer.keepIn")}</FooterTitle>
              <Text className={text}>
                {t("footer.subscribe_message")}
                <div className="text-[16px]">
                  <HtmlTooltip
                    arrow
                    placement="bottom"
                    className="font-sans"
                    title={
                      <div className="p-2">{t("footer.hover_message")}</div>
                    }
                  >
                    <InfoOutlinedIcon
                      fontSize="inherit"
                      className="text-[#f8bc04]"
                    />
                  </HtmlTooltip>
                </div>
              </Text>

              <div className="w-full">
                <Input
                  className={InputStyle}
                  placeholder={t("footer.email")}
                  autoComplete="off"
                  role="presentation"
                  value={state.email}
                  onChange={(e) =>
                    setState((prev) => ({ ...prev, email: e.target.value }))
                  }
                  type={"email"}
                  required
                  maxlength="35"
                />
                <Input
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      postalCode: e.target.value,
                    }))
                  }
                  value={state.postalCode}
                  className={InputStyle}
                  placeholder={t("footer.postal_code")}
                  autoComplete="off"
                  role="presentation"
                  type={"text"}
                  required
                  maxlength="30"
                />
              </div>
              <Button
                disabled={state.email === "" || state.postalCode === ""}
                style={{ width: "183px", height: "50px" }}
                variant="secondary"
                onClick={() => subscribe()}
                text={
                  state.loading ? (
                    <div className="flex justify-center w-full">
                      <div className="w-6 h-6 border-4 border-white/10 border-t-white border-solid rounded-full animate-spin" />
                    </div>
                  ) : (
                    <Text className="font-sans text-[18px] font-semibold ">
                      {t("footer.subscribe")}
                    </Text>
                  )
                }
              />
            </FooterDiv>
          )}
        </div>
        <div className={Copyright}>
          <Text className="text-white text-[13px] opacity-70">
            {t("footer.copyright")}
          </Text>
        </div>
      </div>
    </div>
  );
};

const FooterDiv = tw.div`
w-fit 
h-auto 
flex 
flex-col 
items-start 
justify-start 
gap-y-4
`;
const FooterTitle = tw.span`
text-[1.125rem] 
font-semibold 
text-white
text-md
`;
const text = `
text-[13px]
text-white
opacity-70
`;

const FooterContainer = `
w-full 
h-auto 
bg-black 
flex 
flex-wrap 
justify-between 
items-start 
gap-x-6 
gap-y-20 
py-20 
px-6`;

const InputStyle = `
w-full 
bg-[white] 
text-[gray] 
rounded-lg 
py-[11px] 
mb-2
px-4 
leading-tight 
focus:outline-none 
focus:border-[gray] 
focus:bg-[#d9d9d9]
placeholder:text-[gray]`;

const Copyright = `
w-full 
h-auto 
py-6 
flex 
items-center 
justify-center 
border-t-[0.1px] 
border-t-[#a9a9a9] 
bg-black`;

const footerText = `
flex 
flex-row 
items-center  
gap-[3px] 
no-underline 
hover:underline 
decoration-white 
underline-offset-4 
hover:cursor-pointer`;

export default Footer;

export const FEATURED_CLUBS_NEAR_ME = "FEATURED_CLUBS_NEAR_ME";
export const BEST_CLUBS_IN_COUNTRY = "BEST_CLUBS_IN_COUNTRY";
export const BANNER = "BANNER";
export const TODAYS_BEST_DEAL_NEAR_ME = "TODAYS_BEST_DEAL_NEAR_ME";
export const PLAY_NINE_CLUBS = "PLAY_NINE_CLUBS";
export const DRIVING_RANGE_CLUBS_NEAR_ME = "DRIVING_RANGE_CLUBS_NEAR_ME";
export const PUTTING_GREEN_CLUBS_NEAR_ME = "PUTTING_GREEN_CLUBS_NEAR_ME";
export const TEACHING_PRO_CLUBS_NEAR_ME = "TEACHING_PRO_CLUBS_NEAR_ME";
export const PITCHING_AREA_CLUBS_NEAR_ME = "PITCHING_AREA_CLUBS_NEAR_ME";
export const EXPOLRE_GAME = "EXPOLRE_GAME";
export const COUNTRY_LIST = "COUNTRY_LIST";
export const DESTINATION_LIST = "DESTINATION_LIST";
export const DESTINATION_DETAIL = "DESTINATION_DETAIL";
export const LATEST_REVIEW = "LATEST_REVIEW";
export const REVIEW_RATING = "REVIEW_RATING";
export const CLUB_DETAIL = "CLUB_DETAIL";
export const CLUB_POLICY = "CLUB_POLICY";
export const CLUB_TEE_TIME = "CLUB_TEE_TIME";
export const CATCHING_TEETIME = "CATCHING_TEETIME";
export const CLUB_SEARCH = "CLUB_SEARCH";
